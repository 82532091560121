/* ==========================================================================
   #CAROUSEL
   ========================================================================== */

/**
 * Communication with JS.
 */
:root {
  --c-carousel-touch: 0;
  @include mq-narrow-touch {
    --c-carousel-touch: 1;
  }
}

.c-carousel {
}

  .c-carousel__wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: horizontal;

    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: none; /* Only supported in Firefox 64+ */

    &::-webkit-scrollbar {
      display: none;
    }

    /* Disable native scrolling when the JS carousel takes over. */
    .swiper-container-initialized > & {
      overflow: unset;
      /* https://developers.google.com/web/updates/2017/01/scrolling-intervention */
      touch-action: pan-y pinch-zoom;
    }
  }

  .c-carousel__caption {
    z-index: 1;

    position: fixed;
    top: $global-spacing-unit;

    padding: auto;
    width: 100%;
    text-align: center;

    /*
     * Prevent automatic line breaks: possible line breaks are manually set in
     * the captions.
     * EDIT: Doesn't work on Firefox; we need to revert to non-breaking spaces.
     */
    /*white-space: nowrap;*/

    @include mq-narrow {
      top: unset;
      //bottom: calc(100vh - var(--vh, 1vh) * 100 + #{$global-spacing-unit});
      bottom: $global-spacing-unit;
    }
  }

  .c-carousel__slide {
    height: 100%;

    /** FULL-HEIGHT FIX */
    @include mq-touch {
      /*
       * Overrides the custom width set in the style attribute with the new
       * vh unit computed by Javascript.
       * From From https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
       */
      width: calc(var(--vh, 1vh) * var(--mobile-width)) !important;
    }

    /* Allows to create a slight overlap between the slides; cf. below. */
    overflow: visible;

    /** The <figure> is just there for accessibility purposes. */
    figure {
      height: 100%;
    }

    figcaption {
      display: none;
    }
  }

    .c-carousel__picture {
    }

    .c-carousel__slide img {
      display: block;

      /*
       * Force GPU acceleration to prevent flickering on Safari. However, it
       * caused thin white lines to appear between the images. That is solved
       * by creating an overlap with a width slightly larger than 100%.
       *
       * 10/2022: It actually creates too many layers (on per image, two per
       * slide), which can cause serious performance issues in some browsers,
       * Safari specifically. Disabled.
       * N.B.: Check that it is also disabled in the carousel's vendor styles.
       */
      //transform: translateZ(0);

      /*
       * Instead of 100%: fixes a bug on Blink browsers where the picture itself
       * would scale correctly but the parent keeping its original width, thus
       * creating a big white space.
       */
      height: 100vh;

      /*
       * The size of the slide is given in the HTML style attribute in vh, so
       * we just need the image to cover everything.
       * For the unusual width setting, see previous comment on transform.
       */
      width: 100.2%;
      object-fit: cover;

      /** FULL-HEIGHT FIX */
      @include mq-touch {
        height: calc(var(--vh, 1vh) * 100);
      }

      /*
       * Hide alt text which is displayed before lazy loading on Safari.
       */
      color: transparent;

      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
    }


/* Carousel buttons
   ========================================================================== */

.c-carousel__button {
  position: absolute;
  top: 0;
  height: 100%;
  width: 30%;

  background: transparent;
  cursor: unset;
  z-index: 1;

  border: none;

  &:focus {
    outline: unset;
  }

  @include mq-narrow-touch {
    /* On touch-screens, scrolling is only done with swiping. */
    visibility: hidden;
  }

  .c-carousel--glider > & {
    display: none;
  }
}

.c-carousel__button--prev {
  left: 0;
  cursor: w-resize;
}

.c-carousel__button--next {
  right: 0;
  cursor: e-resize;
}


/* Swiper customization
   ========================================================================== */

.swiper-slide {
  width: unset;
}
