/* ==========================================================================
   #LOGO
   ========================================================================== */

.c-logo {
  z-index: 20;
  position: absolute;
  top: $global-spacing-unit;
  left: $global-spacing-unit;

  .c-overlay--visible__body & {
    position: fixed;
  }
}

/**
 * The logo will be black by default; white when it needs to be when the overlay
 * with the carousel is visible.
 */
.c-overlay--visible__body .c-logo--white {
  color: white;
}

/**
 * "Europium" animation: when interacted with, the logo behaves like it was
 * activated, like an ember slowly cooling.
 */
.c-logo__animation {
  /* Long transition after clicking/hovering. */
  transition: $logo-transition-duration ease-in color;

  &:active {
    /* Normal transition when hovering/clicking. */
    transition: color 50ms;
  }

  @include mq-hover {
    &:hover {
      transition: color 50ms;
    }
  }
}
