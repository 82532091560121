/* ==========================================================================
   #PAGE
   ========================================================================== */

@use "sass:math";

html {
  font-size: math.div($global-font-size, 16px) * 1em;
  line-height: $global-line-height;
  letter-spacing: -.03em;
  -webkit-text-size-adjust: 100%;

  background-color: $color-secondary;
  color: $color-primary;

  font-family: 'CUSFO', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}

body {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
