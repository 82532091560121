/* ==========================================================================
   #FONTS
   ========================================================================== */

@font-face {
  font-family: 'CUSFO';
  src: url('/static/fonts/bold.woff2') format('woff2'),
       url('/static/fonts/bold.woff')  format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'CUSFO';
  src: url('/static/fonts/bold-italic.woff2') format('woff2'),
       url('/static/fonts/bold-italic.woff')  format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: block;
}
