/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: unset;
  text-decoration: none;
  transition: color 50ms;

  &:active {
    color: $color-accent;
  }

  @include mq-hover {
    &:hover {
      color: $color-accent;
    }
  }
}
