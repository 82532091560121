/* ==========================================================================
   #OVERLAY
   ========================================================================== */

.c-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;

  /* Could use 100 * var(--vh) to adapt to mobile phones (cf. carousel) but
   * it's better to leave a white background where the browser bar was instead
   * of the text in the background when scrolling down. */
  height: 100vh;

  z-index: 3;
  background-color: $color-secondary;

  /* Transition with logo delay from visible to invisible  */
  transition: opacity 0.5s ease-in-out $logo-transition-duration, visibility 0.5s ease $logo-transition-duration;

  visibility: hidden;
  opacity: 0;

  .c-overlay__content {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
}

.c-overlay--visible {
  /* Transition without logo delay from invisible to visible */
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease;
  visibility: visible;
  opacity: 1;

  .c-overlay__content {
    opacity: 1;
  }
}

/**
 * Alternate setup where a class is added to <body> rather than directly on the
 * overlay block.
 */
.c-overlay--visible__body {
  /* Overflow is allowed in order to facilitate viewing the full height of the
   * page on the Instagram in-app browser with which the viewport JS fix doesn't
   * work. */
  //overflow: hidden;

  .c-overlay {
    @extend .c-overlay--visible;
  }
}
