/* ==========================================================================
   #SETTINGS
   ========================================================================== */

$global-font-size: 16px;
$global-line-height: 0.95;

$global-spacing-unit: 6px;

$color-primary: #000;
$color-secondary: #fff;
$color-accent: red;

$logo-transition-duration: 1.5s;


/* ==========================================================================
   #MEDIA-QUERIES
   ========================================================================== */

$width-breakpoint: 700px;

/**
 * Targets narrow screens for layout purposes.
 * Usage: caption positioning.
 */
@mixin mq-narrow {
  @media (max-width: $width-breakpoint) {
    @content;
  }
}

/**
 * Targets narrow and touch-enabled screens for interactivity purposes.
 * Usage: the carousel needs (native) free-scrolling for screens that are too
 * narrow to display at least one full image and touch-screens.
 */
@mixin mq-narrow-touch {
  @media (max-width: $width-breakpoint), (hover: none) {
    @content;
  }
}

/**
 * Targets touch screens for layout purposes.
 * Usage: mobile browsers have disappearing scrollbars that reduce the viewport
 * to less than 100vh when a page is just opened. This needs to be adressed to
 * have reliable full-height layout.
 */
@mixin mq-touch {
  @media (hover: none) {
    @content;
  }
}

/**
 * Targets devices where the user can hover for animation purposes.
 * Usage: link animation
 */
@mixin mq-hover {
  @media (hover: hover) {
    @content;
  }
}
