/* ==========================================================================
   #UTTILS
   ========================================================================== */

.u-hidden {
  visibility: hidden;
}

.u-notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
