/* ==========================================================================
   #PROSE
   ========================================================================== */

$s-prose-unit: $global-line-height * 2.3rem;

.s-prose {
  h2 {
    margin-top: $global-line-height * 1rem;
    margin-left: $s-prose-unit;
  }

  p {
    text-align: justify;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  blockquote {
    font-size: 0.8rem;
    margin-top: $global-line-height * 2rem;
    margin-left: $s-prose-unit;
  }
}
